<!--

	Diese Componente fügt dem Header die passenden Meta-Tags für SEO hinzu.

	Welche $props gibt es?

	Beispiel Code:
		<MhHeadInfos
			:doLog="true"
			:showDebugPanel="true"
			:useCaching="true"
		></MhHeadInfos>

	TODO remove "headTagClass" wenn es tatsächlich am Schluss gar nicht benutzt wird

	2021-11-22	init

-->

<template>
	<div class="MhHeadInfos" :class="elmClasses">
		<div class="MhHeadInfos__debug" v-if="showDebugPanel">
			<strong>MhHeadInfos</strong><br/>
			<hr/>
			<p style="color: red">Feature: Handle Homepage with Polylang</p>
			<p style="color: red">Bug: og:locale is always like wp lang, but needs to respekt polylang attr</p>
			<p style="color: red">Bug: http://localhost:3000/de/ soll polylang header-tags liefern</p>
			<hr/>
			<!--
			<pre name="headerData.yoast.status" maxheight :style="_.get( headerData, 'yoast.status' ) !== 200 ? 'color: red' : ''">{{_.get( headerData, 'yoast.status' )}}</pre>
			<pre name="headerData.yoast.json.og_locale" maxheight>{{_.get( headerData, 'yoast.json.og_locale' )}}</pre>
			-->
			<pre name="headerData" maxheight>{{headerData}}</pre>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import currentEnv from '@/helper/getCurrentEnv.js'

	/**
	 * @param {String} html representing a html string
	 * @return {Element}
	 */
	function htmlToElement( html ) {
		var template = document.createElement('template')
		//html = html.trim() // Never return a text node of whitespace as the result
		template.innerHTML = html
		return template.content
	}

	/**
	 * @param {String} className representing a class name
	 * @param {DomeNode} queryNode representing the root node to query in
	 */
	function removeElementsByClass( className, queryNode, doLog = false ){
		if( doLog ){
			console.groupCollapsed( 'removeElementsByClass( className, queryNode )')
			console.log('className', className)
			console.log('queryNode', queryNode)
			console.groupEnd()
		}

		const elements = queryNode ? queryNode.getElementsByClassName( className ) : []

		while( elements.length > 0 ){
			elements[0].parentNode.removeChild( elements[0] )
		}
	}

	export default {
		name: 'MhHeadInfos',
		components: {},
		props: {
			doLog: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			showDebugPanel: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			ignoreViews: {
				type     : [Array],
				default  : ()=>{ return [] },
				required : false,
			},
			useCaching: {
				type     : [Boolean],
				default  : true,
				required : false,
			},
		},
		data(){
			return {
				appInfos : {},
				headerData : {},
				headElm : {},
				fetchCache : {},
				fetchId : null,
			}
		},
		watch: {
			'headerData.html': {
				handler: function( to, from ) {
					this.insertHeaderHtml()
				},
				immediate : false,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			currentUrl(){
				const host = currentEnv.baseUrl
				const fullPath = this.$route.fullPath
				const url = host + fullPath //window.location.href

				return url
			}
		},
		methods: {
			// insert/update header tags after fetch
			insertHeaderHtml( doLog = false ){
				const html = this._.get( this.headerData, 'html')
				const selectors = this._.get( this.headerData, 'handledSelectors')
				//const tagClassName = this.headTagClass
				const headElm = this.headElm

				if( this.doLog || doLog ){
					console.groupCollapsed( this.$options.name, '• insertHeaderHtml()')
				}

				if( this.doLog || doLog ){
					console.log('html:', html)
				}

				// remove every eventully exisiting header tag thats handled
				// via rest endpoint
				selectors.forEach( (selector)=>{
					const elms = this.headElm.querySelectorAll( selector )

					if( this.doLog || doLog ){
						console.log('selector:', selector)
						console.log('elms:', elms)
					}

					if( elms ){
						elms.forEach(elm=>{
							elm.parentNode.removeChild( elm )
						})
					}
				})

				if( this.doLog || doLog ){
					console.groupEnd()
				}

				// remove previously inserted elements
				//removeElementsByClass( tagClassName, headElm )

				// insert the new header tags
				if( html ) headElm.insertAdjacentHTML('afterbegin', html)
			},
			// fetch headInfos from rest endpoint
			fetchHeaderTags( path, doLog = false ){
				const currentRouteName = this.$route.name
				const isIgnored = this.ignoreViews.includes( currentRouteName )
				const fetchUrl = '/wp-json/mh/v1/headInfos?path=' + path
				const isCached = this._.has( this.fetchCache, fetchUrl ) && this.useCaching
				const fetchId = this._.random( 1000, 9999 )

				this.fetchId = fetchId // id is used to prevent race condition

				if( this.doLog || doLog ){
					console.groupCollapsed( this.$options.name, '• fetchHeaderTags( path )')
					console.log('useCaching:', this.useCaching)
					console.log('currentRouteName:', currentRouteName)
					console.log('isIgnored:', isIgnored)
					console.log('isCached:', isCached)
					console.log('path:', path)
					console.log('fetchUrl:', fetchUrl)
					console.log('fetchId:', fetchId)
					console.groupEnd()
				}

				// bail early if ignored
				if( isIgnored ) return

				// if response is cached: use the cached obj
				if( isCached ){
					const result = this._.get( this.fetchCache, fetchUrl )

					if( this.doLog || doLog ){
						console.groupCollapsed( this.$options.name, '• fetchHeaderTags( path ) • from cache')
						console.log('path:', path)
						console.log('result:', result)
						console.groupEnd()
					}

					this.headerData = result
				}
				// response is not cached yet: do the fetch
				else{
					fetch( fetchUrl, {
						method : 'GET',
					})
					.then( response => response.json() )
					.then( data => {
						const result = data.result
						const isLatestFetch = fetchId === this.fetchId // prevent race condition

						this.fetchCache[fetchUrl] = result

						if( this.doLog || doLog ){
							console.groupCollapsed( this.$options.name, '• fetchHeaderTags( path ) • callback')
							console.log('path:', path)
							console.log('result:', result)
							console.log('isLatestFetch:', isLatestFetch)
							console.groupEnd()
						}

						if( isLatestFetch ) this.headerData = result
					})
				}
			},
		},
		created(){},
		mounted(){
			// cache head elm
			this.headElm = document.querySelector('head')

			// fetch headInfos from rest endpoint
			// on load and after every route change
			this.$router.onReady((doLog = false) => {
				const path = this.$route.fullPath

				if( this.doLog || doLog ){
					console.groupCollapsed( this.$options.name, '• $router.onReady()')
					//console.log('from', from)
					//console.log('  to', to)
					console.log('path:', path)
					console.groupEnd()
				}

				this.fetchHeaderTags( path )

				this.$router.afterEach((to, from, doLog = true) => {
					this.fetchHeaderTags( this.$route.fullPath )
				})
			})
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";
	@import (reference) "@/less/atoms.less";

	.MhHeadInfos { // debug
		[showBorders2] & {}
	}
	.MhHeadInfos { // vars
	}
	.MhHeadInfos { // layout
		&__debug {
			position: fixed;
			right: 1em; bottom: 2em;
			max-width: 500px;
			max-height: calc( 100vh - 4em );
			z-index: 10;
			overflow: auto;
		}
	}
	.MhHeadInfos { // styling
		&__debug {
			border: 1px solid;
			padding: 0.5em;
			background-color: lighten( yellow, 35 );
			font-size: 12px;
			line-height: 1.2em;
			box-shadow: 0 0 10px fade( black, 25 );
		}
		&__debug p + p {
			border-top: 1px dotted;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
