/*

	Helper mixin extends a view component with some useful methods

	Emits 'ViewEvent' with payload
		{
			fromComponent : name of the previous view component, e.g. PageView
			eventName     : beforeDestroy | beforeMount | mounted
		}

	Exposes $data.viewIsReady boolean which is false by default
		if set to true it $emits 'ViewEvent' with eventName 'ready'
		usage example, set true if view content is fetched:
			mounted() {
				this.fetchPost(()=>{
					this.viewIsReady = true
				})
			}

	Exposes $methods.viewEventHandler( payload ) function

	It handles scroll position between navigation
		to get this working you need to add to your router.js:

		// Handle scrollRestoration manual, stops browser from doing it
		if('scrollRestoration' in window.history) {
			window.history.scrollRestoration = 'manual';
		}

	2020-03-25	erweiterung
	2019-09-28	init

*/
import EventBus from '@/helper/EventBus.js'

function XXXremoveClassStartsWith(el, classStartsWith) {
	if (!el || !classStartsWith) return

	//var el = document.querySelector('div[class^="ativo"]');
	el.classList.forEach(className => {
		if (className.startsWith(classStartsWith)) {
			el.classList.remove(className);
		}
	})
}

export default {
	metaInfo() {
		return {
			title: this.title,
			titleTemplate: this.titleTemplate,
			meta: [],
			link: [],
		}
	},
	watch: {
		viewIsReady(to, from) {
			if (to) {
				EventBus.$emit('ViewEvent', {
					fromComponent: this.$options.name,
					eventName: 'ready',
					//vibrantColor  : this.vibrantColor, // wird nur vom LuProductView gesetzt
					//viewBackgroundColor  : this.viewBackgroundColor,
					//viewTextColor  : this.viewTextColor,
				})
			}
		},
	},
	data() {
		return {
			title: '',
			titleTemplate: (titleChunk) => {
				// re-replace ampersand '&#038;' back to '&'' in title
				const cleanTitleChunk = titleChunk.replace('&#038;', '&')
				// If undefined or blank then we don't need the hyphen
				return cleanTitleChunk ? `${cleanTitleChunk} – digi.camp` : 'digi.camp';
			},

			viewIsReady: false,
			scrollRestoration: {
				listen: true
			},
		}
	},
	computed: {},
	methods: {
		viewEventHandler(payload) {
			const doLog = false
			const currentComponent = this.$options.name

			if (doLog) {
				console.groupCollapsed('%c' + currentComponent + ': $on("ViewEvent")', 'background-color: yellow', '•', payload.fromComponent, payload.eventName)
				console.log('payload:', payload)
				console.groupEnd()
			}
		},
		scrollEventHandler() {
			const doLog = false
			const scrollY = window.pageYOffset
			const currentComponent = this.$options.name
			const urlPath = this.$route.path

			if (this.scrollRestoration.listen) {

				if (doLog) {
					console.log(currentComponent + ': scrollEventHandler() • set sessionStorage.scrollY:', scrollY)
					console.log('urlPath:', urlPath)
				}

				sessionStorage.setItem(urlPath + ' scrollY', scrollY)
			}
		},
		scrollRestorationHandler(payload) {
			const doLog = false
			const currentComponent = this.$options.name
			const fromComponent = payload.fromComponent
			const eventName = payload.eventName
			const urlPath = this.$route.path
			const scrollY = sessionStorage.getItem(urlPath + ' scrollY') ? parseInt(sessionStorage.getItem(urlPath + ' scrollY')) : 0

			if (eventName == 'beforeDestroy') {
				//console.log('ViewEvent: beforeDestroy()')
			}
			if (eventName == 'beforeMount') {
				if (doLog) console.log(currentComponent + ': beforeMount() • set window.scrollTo(0, 0)')
				this.scrollRestoration.listen = false
				window.scrollTo(0, 0)
			}
			if (eventName == 'mounted') {
				//console.log('ViewEvent: mounted()')
			}
			if (eventName == 'ready') {
				if (doLog) console.log(currentComponent + ': ready() • scrollTo() sessionStorage.scrollY:', scrollY)

				this.$nextTick(() => {
					window.scrollTo(0, scrollY)
				})

				this.scrollRestoration.listen = true

				setTimeout(() => {
					const scrollY = window.pageYOffset
					if (doLog) console.log(currentComponent + ': delayed ready() • window.pageYOffset:', scrollY)
				}, 1000)
			}
		},
	},
	beforeCreate() {},
	created() {
		EventBus.$on('ViewEvent', this.viewEventHandler)

		window.addEventListener('scroll', this.scrollEventHandler)
	},
	beforeMount() {
		EventBus.$emit('ViewEvent', {
			fromComponent: this.$options.name,
			eventName: 'beforeMount',
		})
	},
	mounted() {
		EventBus.$on('ViewEvent', this.scrollRestorationHandler)

		EventBus.$emit('ViewEvent', {
			fromComponent: this.$options.name,
			eventName: 'mounted',
		})
	},
	beforeDestroy() {
		//const scrollY = window.pageYOffset
		//console.log('beforeDestroy: scrollY: ', scrollY )

		EventBus.$emit('ViewEvent', {
			fromComponent: this.$options.name,
			eventName: 'beforeDestroy',
		})

		EventBus.$off('ViewEvent', this.viewEventHandler)
		EventBus.$off('ViewEvent', this.scrollRestorationHandler)

		window.removeEventListener('scroll', this.scrollEventHandler)
	},
}
