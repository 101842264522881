<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcBookEvent" :class="elmClasses">
		<div class="DcBookEvent__inner DcBookEvent__btnHolder">
			<div class="DcBookEvent__col DcBookEvent__col--middle">
				<div class="DcBookEvent__label DcBookEvent__label--border background" :class="backgroundClass">
					<a class="DcBookEvent__labelText color color--inherit" role="button" @click="openIFrameHolder()">
						Termin buchen
					</a>
				</div>
			</div>
		</div>
		<div class="DcBookEvent__inner DcBookEvent__iframeHolder background" :class="iFrameHolderClasses">
			<div class="DcBookEvent__col DcBookEvent__col--middle">
				<Icon class="DcBookEvent__closeBtn" role="button" type="cross" @click.native="closeIFrameHolder()"></Icon>
				<div class="DcBookEvent__label DcBookEvent__label--open">
					<a class="DcBookEvent__labelText color color--inherit" role="button" @click="closeIFrameHolder()">
						Termin buchen
					</a>
				</div>
			</div>
			<div class="DcBookEvent__col DcBookEvent__col--grow">
				<div class="DcBookEvent__iframeWrapper">
					<MhSpinner class="DcBookEvent__iframeLoadingSpinner"
						v-if="!showIframe"
					></MhSpinner>
					<iframe class="DcBookEvent__iframe"
						src="https://www.terminland.de/digi/?mode=frame"
						width="100%" height="100%"
						scrolling="auto"
						ref="iframe"
						v-if="showIframe"
						marginwidth="0" marginheight="0" frameborder="0"
						vspace="0" hspace="0" allowtransparency="true"
						XXXload="onLoadIFrame"
					>
						<p>
							Ihr Browser kann leider keine eingebetteten Frames anzeigen:
							Sie können die Online-Terminbuchung über den folgenden Verweis aufrufen:
							<a href="https://www.terminland.de/digi/">Online-Terminbuchung</a>
						</p>
					</iframe>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import Icon from '@/components/Icon.vue'
	import MhSpinner from '@/components/MhSpinner/MhSpinner.vue'

	export default {
		name: 'DcBookEvent',
		components: {
			Icon,
			MhSpinner,
		},
		mixins: [],
		props: {},
		data() {
			return {
				isOpen : false,
				showIframe : false,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			backgroundClass(){
				let classes = []

				if( this.app.footerColor == 'magenta' ){
					classes.push('background--gelb')
				}
				else{
					classes.push('background--magenta')
				}

				return classes
			},
			iFrameHolderClasses(){
				let classes = []

				if( this.app.footerColor == 'magenta' ){
					classes.push('background--gelb')
				}
				else{
					classes.push('background--magenta')
				}

				if( this.isOpen ) classes.push('DcBookEvent__iframeHolder--isOpen')

				return classes
			},
		},
		methods: {
			clickBtn( action ){
				alert('TODO: clickBtn('+action+')')
			},
			XXXonLoadIFrame(){
				const iFrameElm = this.$refs.iframe

				/*
				setTimeout( ()=>{
					let doc = iFrameElm.contentDocument;

					console.log('onLoadIFrame')
					console.log('iFrameElm', iFrameElm)
					console.log('doc', doc)

					doc.body.innerHTML = doc.body.innerHTML + '<style>body { opacity:0.3; }</style>';
				}, 2000 )
				*/
			},
			openIFrameHolder(){
				this.isOpen = true
			},
			closeIFrameHolder(){
				this.isOpen = false
			},
			windowLoadHandler(){ // show iframe if content is fully loaded
				setTimeout(()=>{
					this.showIframe = true
				}, 500)
			}
		},
		created() {
			window.addEventListener('load', this.windowLoadHandler )
		},
		mounted() {},
		beforeDestroy(){
			window.removeEventListener('load', this.windowLoadHandler )
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcBookEvent {
		[showBorders4] & { .outline( green ); }

		position: fixed;
		top: 0; bottom: 0;
		right: 0; left: 0;
		z-index: 50;
		display: flex;
		flex-direction: row;
		pointer-events: none;

		&__inner {
			transition: all 0.2s ease !important; // important is need to overwrite the background-class transition property
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			display: flex;
			flex-direction: row;
			margin-right: 0 !important;
			transform: translateX( 0% );
		}
		&__btnHolder {
			justify-content: flex-end;
		}
		&__iframeHolder {
			border: 1px solid black;
			border-right: none;
			border-top-left-radius: 7px;
			border-bottom-left-radius: 7px;
			transform: translateX( 100% );
			pointer-events: auto;
		}
		&__iframeHolder--isOpen {
			transform: translateX( 0% );
		}

		&__col {
			[showBorders4] & { background-color: fade( blue, 30 ); outline: 1px solid blue };

			position: relative;
			display: flex;
		}
		&__col--middle { align-items: center; }
		&__col--grow { flex-grow: 1; }

		&__label {
			display: flex;
			justify-content: center;
			align-items: center;
			pointer-events: auto;
		}
		&__labelText {
			padding: 0.75em 0;
			line-height: 1em;
			transform: rotate(-180deg);
			writing-mode: vertical-rl;
			white-space: nowrap;
		}
		&__label--border {
			border: 1px solid black;
			border-right: none;
			border-top-left-radius: 7px;
			border-bottom-left-radius: 7px;
		}

		&__closeBtn {
			position: absolute;
			top: 0; left: 50%;
			transform: translateX(-50%) translateY(25%);
			cursor: pointer;

			@enlargeBy : 0.35em;
			&::before { // enlarge clickable area
				//background-color: fade(blue, 50);

				position: absolute;
				top: -@enlargeBy; left: -@enlargeBy; right: -@enlargeBy; bottom: -@enlargeBy;
				content: "";
			}
		}
		&__iframeWrapper {
			display: flex;
			flex-grow: 1;
			//background-color: fade( blue, 50 );
			//padding: 11px;
		}
		&__iframeLoadingSpinner {
			[showBorders4] & { background-color: yellow; }

			transition: all 0.1s ease 0s;
			position: absolute;
			top: 48%; left: 50%;
			transform: translate(-50%);
			z-index: 20;

			pointer-events: none;
			stroke-width: 4px;
		}
		&__iframe {}

		@media @mediaQuery__dt {
			&__inner {
				margin: 80px @app[dtColGap];
			}
			&__iframeHolder {
				//@vw : 12.5vw * 3;
				@vw : 50vw;
				@px : 8px;
				left: calc( @vw - @px );
			}

			&__label { width: 40px; }
		}
		@media @mediaQuery__md {
			&__inner { margin: @app[mdColGap]; }

			&__label { width: 40px; }
		}
		@media @mediaQuery__sm {
			&__inner { margin: @app[smColGap]; }

			&__label { width: 33px; font-size: 20px; }
			&__closeBtn { min-width: 0.75em; min-height: 0.75em; transform: translateX(-55%) translateY(25%) }
		}
	}
</style>
