<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcCookieNotice" :class="elmClasses">
		<div class="DcCookieNotice__inner hSpace hSpace--colGap">
			<div class="DcCookieNotice__grid grid grid--cols-8">
				<div class="DcCookieNotice__gridInner hSpace hSpace--colGap vSpace vSpace--rowGap">

					<div class="DcCookieNotice__row">
						<div class="DcCookieNotice__text">
							Diese Website verwendet Cookies,
							um Zugriffe zu analysieren.<br />
							<br />
							<br />
							<br />
						</div>
						<div class="DcCookieNotice__image"></div>
					</div>

					<div class="DcCookieNotice__row flex flex--middle">
						<router-link
							class="DcCookieNotice__privacyLink"
							:to="'/datenschutz/'"
						>Mehr zum Datenschutz</router-link>

						<button class="btn"
							role="button" @click="clickOK()">
							<div>
								<span></span>
								<span></span>
							</div>
							Okay
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'DcCookieNotice',
		components: {},
		mixins: [],
		props: {},
		data() {
			return {
				isConfirmed : null,
				isHidden    : true,
				storageVar  : 'DcCookieNotice: isConfirmed'
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isHidden ) classes.push('DcCookieNotice--isHidden')

				return classes
			},
		},
		methods: {
			clickOK() {
				localStorage.setItem( this.storageVar, 1 )
				this.isConfirmed = true
				this.isHidden = true
			},
		},
		created() {},
		mounted() {
			let isHidden = false

			if( parseInt( localStorage.getItem( this.storageVar ) ) === 1 ) {
				isHidden = true
			}else{
				isHidden = false
			}

			setTimeout(()=>{
				this.isHidden = isHidden
			}, 500)
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcCookieNotice {
		position: fixed;
		left: 0; right: 0;
		bottom: 0;
		pointer-events: none;
		z-index: 100;

		transition: all 0.2s ease;
		transform: translateY( 0% );

		&--isHidden {
			transform: translateY( 100% );
		}

		&__inner {}
		&__grid {}

		&__gridInner {
			border-radius: 7px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			background-color: @swatches[yellow];
			pointer-events: auto;
		}

		&__row {
			[showBorders3] & { outline: 1px solid red; }
			display: flex;
			justify-content: space-between;
		}

		&__text {
			width: 75%;
			margin-top: -0.2em; // vertical offset fix
		}
		&__image {
			[showBorders3] & { background-color: fade( red, 20 ); }

			width: 25%;
			height: 4em;
			background-image: url('../assets/imgs/img--cookie.svg');
			background-position: top right;
			background-repeat: no-repeat;
			background-size: contain;
		}
		&__privacyLink {
			.font--sizeSmall;

			color: inherit;
		}

		@media @mediaQuery__dt {
			&__gridInner { grid-column-start: 3; grid-column-end: 7; }
		}
		@media @mediaQuery__md {
			&__gridInner { grid-column: span 8; }
		}
		@media @mediaQuery__sm {
			&__gridInner { grid-column: span 8; }

			&__text { .font--sizeSmall; }
			//&__image { .font--sizeSmall; }
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
