import Vue from 'vue'
import App from './App.vue'
import lodash from 'lodash'
import router from './config/router'
import store from './config/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLodash from 'vue-lodash'
import VShowSlide from 'v-show-slide'
import VueMq from 'vue-mq'
import VueMatomo from 'vue-matomo'
import currentEnv from '@/helper/getCurrentEnv.js'
import EventBus from '@/helper/EventBus.js'

Vue.config.productionTip = false

// on error + warn: play beep sound and show errorBadge
// see: https://vuejs.org/v2/api/#errorHandler
// see: https://vuejs.org/v2/api/#warnHandler
const warnAndErrorHandler = (err, vm, info) => {
	// err: error trace
	// vm: component in which error occured
	// info: Vue specific error information such as lifecycle hooks, events etc.

	// TODO: Perform any custom logic or log to server
	const componentName = vm ? vm.$options.name : ''

	// remove eventually previously added errorBadge
	const prevErrorBadge = document.body.querySelector('errorBadge')
	if (prevErrorBadge) document.body.removeChild(prevErrorBadge)
	// Show red dot as visible error indicator
	const badgeHeight = '150px'
	const errorBadgeElm = document.createElement('div')
	errorBadgeElm.classList.add("errorBadge")
	errorBadgeElm.style.cssText = `
		position: fixed;
		border-radius: 50%;
		top: calc( 50% - ( ${badgeHeight} * 0.5 ) );
		left: calc( 50% - ( ${badgeHeight} * 0.5 ) );
		width: ${badgeHeight};
		height: ${badgeHeight};
		opacity: 0.85;
		z-index: 100000;
		background: red;
	`;
	document.body.appendChild(errorBadgeElm);

	console.group('%cerrorHandler() •', 'color : red', componentName)
	console.error('err:', err)
	console.log('%cvm:', 'color : red', vm)
	console.log('%cinfo:', 'color : red', info)
	console.groupEnd()

	const a = new AudioContext() // browsers limit the number of concurrent audio contexts, so you better re-use'em

	function beep(vol, freq, duration) {
		const v = a.createOscillator()
		const u = a.createGain()
		v.connect(u)
		v.frequency.value = freq
		v.type = "square"
		u.connect(a.destination)
		u.gain.value = vol * 0.01
		v.start(a.currentTime)
		v.stop(a.currentTime + duration * 0.001)
	}

	beep(10, 320, 150)
}
if (currentEnv.name != 'live') {
	Vue.config.warnHandler = warnAndErrorHandler
	Vue.config.errorHandler = warnAndErrorHandler
}

Vue.use(VueAxios, axios)
Vue.use(VueLodash, {lodash: lodash})
Vue.use(VShowSlide)
Vue.use(VueMq, {
	breakpoints: {
		xs: 500,
		sm: 715,
		md: 1130,
		dt: 1364,
		lg: 1365,
		xl: 1950,
	}
})

// add matomo only on live env
// config docu here: https://github.com/AmazingDreams/vue-matomo#readme
if (currentEnv.name == 'live') {
	Vue.use(VueMatomo, {
		// Configure your matomo server and site by providing
		host: 'https://matomo.digi.camp',
		siteId: 1,

		// Changes the default .js and .php endpoint's filename
		// Default: 'matomo'
		trackerFileName: 'matomo',

		// Overrides the autogenerated tracker endpoint entirely
		// Default: undefined
		// trackerUrl: 'https://example.com/whatever/endpoint/you/have',

		// Overrides the autogenerated tracker script path entirely
		// Default: undefined
		// trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

		// Enables automatically registering pageviews on the router
		router: router,

		// Enables link tracking on regular links. Note that this won't
		// work for routing links (ie. internal Vue router links)
		// Default: true
		enableLinkTracking: true,

		// Require consent before sending tracking information to matomo
		// Default: false
		requireConsent: false,

		// Whether to track the initial page view
		// Default: true
		trackInitialView: true,

		// Run Matomo without cookies
		// Default: false
		disableCookies: true,

		// Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
		// Default: false
		enableHeartBeatTimer: false,

		// Set the heartbeat timer interval
		// Default: 15
		heartBeatTimerInterval: 15,

		// Whether or not to log debug information
		// Default: false
		debug: true,

		// UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
		// Default: undefined
		userId: undefined,

		// Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
		// Default: undefined, example '*.example.com'
		cookieDomain: undefined,

		// Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
		// Default: undefined, example: '*.example.com'
		domains: undefined,

		// A list of pre-initialization actions that run before matomo is loaded
		// Default: []
		// Example: [
		//   ['API_method_name', parameter_list],
		//   ['setCustomVariable','1','VisitorType','Member'],
		//   ['appendToTrackingUrl', 'new_visit=1'],
		//   etc.
		// ]
		preInitActions: []
	})
}

// Setup and mount root
new Vue({
	router,
	store,
	data() {
		return {
			currentEnv: currentEnv
		}
	},
	watch: {},
	computed: {
		isMqBelowMd() {
			if (this.$mq === 'xs' || this.$mq === 'sm') {
				return true
			} else {
				return false
			}
		},
		isMqBelowDt() {
			if (this.$mq === 'xs' || this.$mq === 'sm' || this.$mq === 'md') {
				return true
			} else {
				return false
			}
		},
		isIE() {
			return !!window.MSInputMethodContext && !!document.documentMode;
		},
	},
	methods: {},
	created() {
		console.log('currentEnv:', currentEnv)
		this.$router.onReady(() => {})
	},
	mounted() {},
	render: h => h(App)
}).$mount('#app')
