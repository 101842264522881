<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="Icon" :class="elmClasses">
		<span></span>
		<span></span>
		<span></span>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	//import DevInfos from '@/components/DevInfos.vue'

	export default {
		name: 'Icon',
		components: {},
		mixins: [],
		props: {
			type: [String, Boolean],
		},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push('Icon--type-' + this.type)

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.Icon {
		[showBorders3] & { .outline( red ); }

		position: relative;
		min-height: 1em; min-width: 1em;
		display: inline-flex;
	}
	.Icon--type-cross {
		span {
			position: absolute;
			top: 50%; left: 0; right: 0;
			display: block;
			width: 100%;
			min-height: 3px;
			background-color: #000;
		}
		span:nth-child(1) { transform: rotate(-45deg);}
		span:nth-child(2) { background-color: red; display: none; }
		span:nth-child(3) { transform: rotate(45deg);}
	}
	.Icon--type-hamburger {
		flex-flow: column;
		justify-content: space-between;
		align-items: center;
		flex-shrink: 0;

		span {
			display: block;
			width: 100%;
			min-height: 2px;
			background-color: #000;
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
