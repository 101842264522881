<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcHeader contentModule background" :class="elmClasses">
		<div class="DcHeader__inner grid grid--cols-8 hSpace hSpace--colGap">
			<div class="DcHeader__navCol">
				<nav class="DcHeader__dtNavItems" v-if="!$root.isMqBelowDt && app.acfOptions">
					<router-link class="DcHeader__dtNavItemLink color color--inherit"
						:to="'/' + navItem.link.post_name + '/'"
						v-for="(navItem, index) in app.acfOptions.mainNavItems" :key="index"
					>{{navItem.title}}</router-link>
				</nav>
				<a class="DcHeader__toggleMainNav" role="button" @click="clickToggleBtn" v-if="$root.isMqBelowDt">
					<Icon class="DcHeader__hamburgerIcon" type="hamburger"></Icon>
				</a>
			</div>
			<div class="DcHeader__logoCol">
				<router-link class="DcHeader__homeLink" :to="{ name: 'HomeView' }"
				>digi.camp</router-link>
			</div>
		</div>
		<!--
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import Icon from '@/components/Icon.vue'

	export default {
		name: 'DcHeader',
		components: {
			Icon,
		},
		mixins: [],
		props: {},
		data() {
			return {
				//isActiveToggleBtn : false,
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.app.headerColor ){
					classes.push('background--' + this.app.headerColor)
					//console.log('DcHeader: elmClasses()', this.app.headerColor)
				}

				return classes
			},
		},
		methods: {
			clickToggleBtn(){
				EventBus.$emit('open DcMainNav')

				/*
				if( this.isActiveToggleBtn ){
					EventBus.$emit('close DcMainNav')
					this.isActiveToggleBtn = false
				}else{
					EventBus.$emit('open DcMainNav')
					this.isActiveToggleBtn = true
				}
				console.log('clickToggleBtn()')
				*/
			},
		},
		created() {},
		mounted() {},
		beforeDestroy(){},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.text-crop(@line-height: 1.3, @top-adjustment: 0px, @bottom-adjustment: 0px) {
	    // Configured in Step 1
	    @top-crop: 9;
	    @bottom-crop: 8;
	    @crop-font-size: 36;
	    @crop-line-height: 1.2;

	    // Apply values to calculate em-based margins that work with any font size
	    @dynamic-top-crop: max((@top-crop + (@line-height - @crop-line-height) * (@crop-font-size / 2)), 0) / @crop-font-size;
	    @dynamic-bottom-crop: max((@bottom-crop + (@line-height - @crop-line-height) * (@crop-font-size / 2)), 0) / @crop-font-size;

	    // Mixin output
	    line-height: @line-height;

	    &::before,
	    &::after {
	        content: '';
	        display: block;
	        height: 0;
	        width: 0;
	    }

	    &::before {
	        margin-bottom: calc(unit(-@dynamic-top-crop, em) ~"+" @top-adjustment);
	    }

	    &::after {
	        margin-top: calc(unit(-@dynamic-bottom-crop, em) ~"+" @bottom-adjustment);
	    }
	}
	// Mixin generated at: http://text-crop.eightshapes.com/?

	/* Usage Examples
	    .my-level-1-heading-class {
	        .text-crop; // Will use default line height of 1.3
	        font-size: 48px;
	        margin: 0 0 0 16px;
	    }

	    .my-level-2-heading-class {
	        .text-crop; // Will use default line height of 1.3
	        font-size: 32px; // Don't need to change any settings, will work with any font size automatically
	        margin: 0 0 0 16px;
	    }

	    .my-body-copy-class {
	        .text-crop(@line-height: 2); // Larger line height desired, set the line height via the mixin
	        font-size: 16px;
	    }

	    // Sometimes depending on the font-size, the rendering, the browser, etc. you may need to tweak the output.
	    // You can adjust the top and bottom cropping when invoking the component using the $top-adjustment and $bottom-adjustment settings

	    .slight-adjustment-needed {
	        .text-crop(@top-adjustment: -0.5px, @bottom-adjustment: 2px);
	        font-size: 17px;
	    }

	    .dont-do-this {
	        .text-crop;
	        font-size: 16px;
	        line-height: 3; // DO NOT set line height outside of the mixin, the mixin needs the line height value to calculate the crop correctly
	    }
	*/

	.DcHeader {
		z-index: 1; // wird gebraucht, weil in manchen situationen die nachfolgenden contentModules nach oben verschoben werden und ansonsten das logo unten abschneiden würden

		&__inner {}
		&__navCol {
			grid-column: span 3;
		}
		&__logoCol {
			grid-column: span 2; text-align: center;
		}

		&__dtNavItems {		// navItems, dt and above
			[showBorders4] & { .relative; .outline( blue ); }

			max-width: 450px;
		}
		&__dtNavItemLink {
			transition: all 0.1s ease;
			display: inline-block;
			margin-right: 0.85em;

			transform: translateY(-6px); // adjust y-offset

			.routerLink--exactActive {}
			&.routerLink--active {
				color: fade( black, 50 );
			}
		}
		&__toggleMainNav {	// hamburger icon wrapper, visible below dt
			[showBorders4] & { .relative; .outline( blue ); }

			position: relative;
			display: flex;
			height: 100%; width: 100%;
			color: transparent;
			align-items: center;
			//font-size: 0;
			//overflow: hidden;
		}
		&__hamburgerIcon {
			span { height: 4px; }
		}
		&__homeLink {		// logo in the middle
			[showBorders4] & { .relative; .outline( blue ); }

			display: inline-block;
			width: 100%; height: 100%;

			background-image: url('../assets/imgs/logo--digicamp.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			color: transparent;
		}

		@media @mediaQuery__dt {
			height: @app[dtHeaderHeight]; overflow: hidden;

			&__inner { padding-top: 15px; padding-bottom: 20px; }
			&__homeLink { width: 250px; height: 50px; }
			&__toggleMainNav { display: none; }
		}
		@media @mediaQuery__md {
			height: @app[mdHeaderHeight]; overflow: hidden;

			&__inner {    padding-top: 12px; padding-bottom: 12px; }
			&__navCol {   grid-column: span 1; }
			&__logoCol {  grid-column: span 6; }
			&__homeLink { width: 250px; height: 50px; }
			&__hamburgerIcon { max-height: 20px; width: 40px; margin-top: 0px; }
			&__hamburgerIcon span { height: 4px; }
		}
		@media @mediaQuery__sm {
			height: @app[smHeaderHeight]; overflow: hidden;

			&__inner {    padding-top: 11px; padding-bottom: 8px; }
			&__navCol {   grid-column: span 2; }
			&__logoCol {  grid-column: span 4; }
			&__homeLink { width: 150px; height: 26px; }
			&__hamburgerIcon { height: 14px; min-height: 14px; max-width: 25px; margin-top: -2px; }
			&__hamburgerIcon span { height: 3px; }
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
