<template>
	<div class="app relative handleModuleBgOverflow handleVSpacing">

		<DcHeader></DcHeader>

		<DcMainNav></DcMainNav>

		<main class="app__body" :class="bodyClasses">
			<transition
				name="fade"
				mode="out-in"
				:duration="{ leave: 200, enter: 300  }"
				:key="$route.path"
				appear>
				<router-view class="app__view" :key="$route.path" />
			</transition>
		</main>

		<DcBookEvent></DcBookEvent>

		<DcCookieNotice></DcCookieNotice>

		<MhSpinner class="app__loadingSpinner" :class="spinnerClasses"></MhSpinner>

		<div class="gridDebug grid grid--cols-8 hSpace hSpace--colGap">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>

		<IEHacks></IEHacks>

		<MhTagsOutlining
			:doLog="true"
			:color="'rgba( 255, 0, 175, 0.5 )'"
			:selectors="[
				'[showborders3] .DcText',
				'[showborders3] .DcImageText',
				'[showborders3] .DcOffer',
				'[showborders3] .DcTeam',
				'[showborders3] .DcLocations',
				'[showborders3] .DcOffers',
			]"
		></MhTagsOutlining>
		<MhHeadInfos
			:doLog="$root.currentEnv.name === 'local'"
			:showDebugPanel="$root.currentEnv.name === 'xxxlocal'"
			:useCaching="true"
		></MhHeadInfos>
		<MhDelegateLinks></MhDelegateLinks>
		<MhDevInfos
			:doLog="false"
			:showOnHosts="[
				'localhost',
				'digicamp.local',
				'dev.digi.camp',
			]"
			:show="_.get( currentUser, 'display_name' ) ? true : undefined"
		></MhDevInfos>
		<!--
		-->
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/RestHandler/RestHandler.js'

	import MhDevInfos from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDevInfos/v2/MhDevInfos.vue'
	import MhHeadInfos from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhHeadInfos/v1/MhHeadInfos.vue'
	import MhDelegateLinks from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDelegateLinks/v5/MhDelegateLinks.vue'
	import MhTagsOutlining from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhTagsOutlining/v1/MhTagsOutlining.vue'
	import MhSpinner from '@/components/MhSpinner/MhSpinner.vue'

	import DcHeader from '@/components/DcHeader.vue'
	import DcMainNav from '@/components/DcMainNav.vue'
	import DcBookEvent from '@/components/DcBookEvent.vue'
	import DcCookieNotice from '@/components/DcCookieNotice.vue'
	import IEHacks from '@/components/IEHacks.vue'

	export default {
		name: 'App',
		components: {
			MhHeadInfos,
			MhDevInfos,
			MhDelegateLinks,
			MhSpinner,
			DcHeader,
			DcMainNav,
			DcBookEvent,
			DcCookieNotice,
			IEHacks,
			MhTagsOutlining,
		},
		directives: {},
		mixins: [ RestHandler ],
		data() {
			return {
				acfOptions  : null,
				postId      : null,

				headerColor : null,
				bodyColor   : null,
				footerColor : null,

				isVisible   : {
					body    : true,
					spinner : false,
				},

				currentUser : null,
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				// classes.push('underline underline--' + this.app.bodyColor )

				return classes
			},
			bodyClasses(){
				let classes = []

				if( this.isVisible.body ) classes.push('app__body--isVisible')

				return classes
			},
			spinnerClasses(){
				let classes = []

				if( this.isVisible.spinner ) classes.push('app__loadingSpinner--isVisible')

				return classes
			},
		},
		methods: {
			fetchAll( finalCallback ){
				let thingsToLoad = 2

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/acfOptions',
					params : {},
					callback : (response) => {
						const doLog  = false
						const result = response.data.result
						const count  = this._.size( result )

						if( doLog ){
							console.groupCollapsed('this.fetchAcfOptions() callback', count)
							console.log(' response:', response)
							console.log('   result:', result)
							console.groupEnd()
						}

						this.acfOptions = result

						runCallback()
					},
				})
				this.restHandler__fetch({
					action : 'GET',
					route : '/json/userInfos',
					params : {},
					callback : (response) => {
						this.currentUser = response.data

						runCallback()
					},
				})
			},
			pageLoadedHandler( post ){ // set app header+body+footer colors depending on current post
				const doLog            = false
				const currentComponent = this.$options.name
				const postId           = post && post.id ? post.id : null
				const postType         = post && post.type ? post.type : null
				let postColor          = post && post.acf.color ? this._.kebabCase( post.acf.color.label.toLowerCase() ) : null

				let headerColor        = null
				let bodyColor          = postColor
				let footerColor        = postColor

				if( this._.isNull( post) ){
					 //headerColor = 'rot'
					 //postColor   = 'rot'
					 footerColor = 'magenta'
				}
				if( postType == 'offer_post' ){
					 headerColor = 'rot'
					 postColor   = 'rot'
					 footerColor = 'rot'
				}
				if( postType == 'feature_post' ){
					 headerColor = 'grun'
					 postColor   = 'grun'
					 footerColor = 'grun'
				}
				if( this.acfOptions && this.acfOptions.links.privacyPolicyPost.ID === postId ){
					 headerColor = 'magenta'
				}

				this.postId      = postId
				this.headerColor = headerColor
				this.bodyColor   = bodyColor
				this.footerColor = footerColor

				if( doLog ){
					console.groupCollapsed('%c' + currentComponent + ': $on("pageLoaded")', 'background-color: lightblue', '•', postType, postColor)
					console.log('post:', post)
					console.log('postId:', postId)
					console.log('postType:', postType)
					console.log('postColor:', postColor)
					console.log('headerColor:', headerColor)
					console.log('bodyColor:', bodyColor)
					console.log('footerColor:', footerColor)
					console.groupEnd()
				}
			},
			viewEventHandler( payload ){
				const doLog = false
				const currentComponent = this.$options.name
				const fromComponent = payload.fromComponent
				const eventName = payload.eventName

				if( doLog ){
					console.groupCollapsed('%c' + currentComponent + ': $on("ViewEvent")', 'background-color: yellow', '•', fromComponent, eventName)
					console.log('fromComponent:', fromComponent)
					console.log('    eventName:', eventName)
					console.groupEnd()
				}

				if( ['beforeDestroy'].includes( eventName ) ){ // reset header before route change to keep the bg transitions (header, body, footer) in sync
					this.headerColor = 'transparent'
				}
				if( ['beforeDestroy', 'beforeMount'].includes( eventName ) ){
					this.isVisible.body    = false
					this.isVisible.spinner = true
				}
				if( ['ready'].includes( eventName ) ){
					this.isVisible.body    = true
					this.isVisible.spinner = false
				}
			},
		},
		created() {
			EventBus.$on('pageLoaded', this.pageLoadedHandler)
			EventBus.$on('ViewEvent', this.viewEventHandler)
		},
		mounted() {
			this.fetchAll()
		},
		beforeDestroy(){
			EventBus.$off('pageLoaded', this.pageLoadedHandler)
			EventBus.$off('ViewEvent', this.viewEventHandler)
		},
	}
</script>

<style lang="less">
	@import "@/less/includes/normalize.include.less";
	@import "@/less/vars.less";
	@import "@/less/mixins.less";
	@import "@/less/atoms.less";
	@import "@/less/fonts.less";

	body {
		.font;
		.font--sans;
		.font--sizeDefault;
		.font--smooth;

		// force scrollbar to prevent content jumping on route changes
		min-height: calc( 100vh + 1px );
	}
	strong, b {
		.font--bold;
	}
	button {
		.button;
		cursor: pointer;
		background-color: transparent;
	}
	h1, h2, h3, h4, h5 {
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		margin: 0;
	}
	a { // handle links
		cursor: pointer;
		color: blue;
		text-decoration: none;

		.DcText &,
		.DcPrivacyPolicy &,
		.DcImageText &,
		.DcOffer__section &,
		.DcFeature__text &,
		.DcExperiences__quote & {
			color: inherit;
			text-decoration-line: underline;
			text-decoration-skip-ink: auto;
			text-underline-offset: 0.11em;
		}
		.DcFooter & {
			color: inherit;
		}
		.DcExperiences__quote & {
			text-decoration-color: currentColor;
		}
		.DcText__text--sizeBig & {
			text-decoration-thickness: 4px;
		}
		.DcText__text--sizeDefault & {
			text-decoration-thickness: 3px;
		}
	}
	pre {
		@color: black;

		position: relative;
		padding: 0.5em;
		width: 100%;
		min-height: 2em;

		margin-bottom: 0.5em;
		&:first-of-type { margin-top: 1em; }
		&:last-of-type { margin-bottom: 1em; }

		//color: fade(@color, 65);
		tab-size: 4;
		white-space: pre;

		outline: 1px solid fade(@color, 25);
		background-color: fade(@color, 5);
		overflow: auto;
		//display: none;

		// label
		&[name]:after {
			position: absolute;
			top: 0; right: 0;
			background-color: fade(@color, 15);
			font-size: 13px;
			line-height: 1em;
			font-family: sans-serif;
			color: fade(@color, 75);
			padding: 0.25em 0.5em;
			content: attr(name);
			font-family: inherit;
		}

		&:first-child {
			margin-top: 0em;
		}
	}
	hr {
		border: none;
		border-top: 1px solid;
		margin: 0.5em 0;
		opacity: 0.25;
	}

	.btn {
		.font--sizeSmall;

		display: inline-flex;
		align-items: center;
		padding: 0 0.6em;
		height: 33px;

		line-height: 1em;
		border: 2px solid;
		border-radius: 7px;
		user-select: none;

		&--submitContactForm {
			color: white;
			padding: 0 2em;
		}

		@media @mediaQuery__dt {
			//padding: 0 0.5em;

			&--submitContactForm {
				height: 43px;
			}
		}
	}
	.card {
		[showborders4] & {
			outline: 1px solid black;
		}

		padding: 10px 12px;
		background-color: white;
		border-radius: 7px;

		&__inner {
			position: relative;
			height: 100%;
		}

		@media @mediaQuery__sm {
			padding: 8px 10px;
		}
	}
	.contentModule {
		[showBorders2] & {
			.outline( fade(green, 30) );
			.label( attr(class), fade(green, 35), black );
		}

		position: relative;
	}
	/*
	.DcText, .DcImageText, .DcOffer, .DcTeam, .DcLocations, .DcOffers { // debug outlines
		[showBorders3] & {
			@color : darken(pink, 10 );

			h1, h2, h3, h4, h5, h6, p, pre, ul, ol {
				position: relative;
				outline: 1px solid @color;
			}
			h1:after,
			h2:after,
			h3:after,
			h4:after,
			h5:after,
			h6:after,
			p:after,
			pre:after,
			ul:after,
			ol:after {
				position: absolute;
				top: 0;
				right: 0;
				color: #fff;
				background-color: @color;
				font-size: 11px;
				padding: 0 5px 1px;
				font-family: arial, sans-serif;
				line-height: 13px;
			}
			h1:after { content: "H1"; }
			h2:after { content: "H2"; }
			h3:after { content: "H3"; }
			h4:after { content: "H4"; }
			h5:after { content: "H5"; }
			h6:after { content: "H6"; }
			p:after  { content: "¶"; }
			pre:after  { content: "PRE"; }
			ul:after  { content: "UL"; }
			ol:after  { content: "OL"; }

			li + li { border-top: 1px dotted @color; }

		}
	}
	*/
	.gridDebug {
		[showborders1] & {
			visibility: visible;
			outline: 1px solid cyan;
		}

		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		min-height: 100vh;
		z-index: 100;
		pointer-events: none;
		visibility: hidden;

		& > * { background-color: fade( cyan, 65 );	}
	}
	.handleModuleBgOverflow {
		@media @mediaQuery__dt {
			.DcImageText__inner {
				position: relative;

				& > * {
					z-index: 1;
				}

				&::before {
					position: absolute;
					top: 0; bottom: 0;
					left: -@app[dtColGap];
					right: -@app[dtColGap];
					background-color: inherit;
					content: "";
					z-index: 0;
				}
			}
		}
	}
	.handleVSpacing {
		.app__view {

			.DcText.contentModule--0 { margin-top: 65px; }
			.DcText.contentModule--last { margin-bottom: 95px; }
			.DcOffers { margin-top: 95px; }
			.DcLocations { margin-top: 95px; margin-bottom: 95px; }
			.DcFeatures { margin-top: 95px; }
			.DcImageText { margin-top: 95px; margin-bottom: 95px; }
			.DcContactForm { margin-top: 35px; margin-bottom: 95px; }

			@media @mediaQuery__sm {
				.DcText.contentModule--0 { margin-top: 5px; }
				.DcText.contentModule--last { margin-bottom: 25px; }
				.DcOffers { margin-top: 25px; }
				.DcLocations { margin-top: 25px; margin-bottom: 25px; }
				.DcFeatures { margin-top: 25px; }
				.DcImageText { margin-top: 25px; margin-bottom: 25px; }
				.DcContactForm { margin-top: 25px; margin-bottom: 40px; }
			}

		}
	}

	.app {}
	.app__body {
		transition: all 0.2s ease;
		position: relative;

		pointer-events: none;
		background-color: white;
		opacity: 0;

		&--isVisible { opacity: 1; pointer-events: auto; }
	}
	.app__view {}
	.app__loadingSpinner {
		[showBorders4] & { background-color: yellow; }

		transition: all 0.1s ease 0s;
		position: fixed;
		top: 48%; left: 50%;
		transform: translate(-50%);
		z-index: 20;

		pointer-events: none;
		stroke-width: 4px;
		opacity: 0;

		&--isVisible { transition-delay: 0.3s; opacity: 1; }
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
