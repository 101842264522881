<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcMainNav contentModule" :class="elmClasses">
		<div class="DcMainNav__inner">
			<a class="DcMainNav__closeBtn" role="button" @click="isOpen = !isOpen">
				<div>
					<span></span>
					<span></span>
				</div>
			</a>
			<ul class="DcMainNav__list reset reset--list hSpace hSpace--colGap">
				<li class="DcMainNav__listItem" v-for="(navItem, index) in navItems" :key="index">
					<router-link class="DcMainNav__link" :class="linkElmClasses( navItem )"
						:to="'/' + navItem.link.post_name + '/'"
						@click.native="clickNavItem()">
						{{navItem.title}}
					</router-link>
				</li>
			</ul>
			<!--
			<pre name="isOpen">{{isOpen}}</pre>
			<pre name="navItems">{{navItems}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import Icon from '@/components/Icon.vue'

	export default {
		name: 'DcMainNav',
		components: {
			Icon,
		},
		mixins: [],
		props: {},
		data() {
			return {
				isOpen : false
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isOpen ){
					classes.push('DcMainNav--isOpen')
				}else{
					classes.push('DcMainNav--isClosed')
				}

				return classes
			},
			navItems(){
				return this.app.acfOptions ? this.app.acfOptions.mainNavItems : []
			}
		},
		methods: {
			linkElmClasses( navItem ){
				const classes = []

				classes.push('background background--' + this._.kebabCase( navItem.link.color.label.toLowerCase() ) )

				return classes
			},
			clickNavItem(){
				this.isOpen = false
			},
		},
		created() {},
		mounted() {
			EventBus.$on('open DcMainNav', ()=>{
				this.isOpen = true
			})
			EventBus.$on('close DcMainNav', ()=>{
				this.isOpen = false
			})
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcMainNav {
		[showBorders4] & { .relative; .outline( green ); }

		transition: all 0.35s ease;
		position: fixed !important;
		top: 0; left: 0;
		width: 100%; height: 100vh;
		z-index: 50;
		background-color: white;

		&__inner {
			display: flex;
			flex-direction: column;
			//height: 100%;
		}
		&__closeBtn {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: white;

			div {
				//background-color: fade( red, 15 );

				display: flex;
				flex-flow: column;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
			}
			span {
				display: block;
				width: 100%;
				height: 4px;
				background-color: #000;
			}
			span:nth-child(1) {
				//background-color: red;
				transform: translateY(50%) rotate( 45deg );
			}
			span:nth-child(2) {
				//background-color: green;
				transform: translateY(-50%) rotate( -45deg );
			}
		}
		&__list {
			background-color: white;
			flex-grow: 1;
		}
		&__listItem {}
		&__link {
			[showBorders4] & { .relative; .outline( green ) };

			display: inline-flex;
			align-items: center;
			padding: 0 13px;
			height: 60px;
			margin-bottom: 15px;

			.font--sizeBig;
			background-color: yellow;
			border-radius: 7px;
			line-height: 1em;
			white-space: nowrap;
			color: inherit;
		}

		&--isOpen {
			//background-color: green;
			transform: translateX(0%);
		}
		&--isClosed {
			//background-color: red;
			transform: translateX(-100%);
		}

		@media @mediaQuery__dt { // wird nur zum entwickeln benötigt
			&__closeBtn {
				height: 74px; width: 74px;

				div { width: 30px; height: 30px; }
			}
			&__list { padding-top: 43px; }
		}
		@media @mediaQuery__md {
			&__closeBtn {
				height: 74px; width: 74px;

				div { width: 30px; height: 30px; }
			}
			&__list { padding-top: 43px; }
		}
		@media @mediaQuery__sm {
			&__closeBtn {
				height: 45px; width: 45px;

				div { width: 22px; height: 22px; }
			}
			&__list { padding-top: 6px; }
			&__link { margin-bottom: 11px; }
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
