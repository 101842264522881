<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="IEHacks"></div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	//import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'IEHacks',
		components: {},
		mixins: [],
		props: {},
		data() {
			return {}
		},
		watch: {},
		computed: {},
		methods: {},
		created() {},
		mounted() {
			// add class "isIE" to html elm
			if( this.$root.isIE ){
				var htmlElm = document.getElementsByTagName("html")[0];
				htmlElm.classList.add("isIE")
			}
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.isIE {
		// handle links
		.DcText a,
		.DcPrivacyPolicy a,
		.DcImageText a,
		.DcOffer__section a,
		.DcFeature__text a,
		.DcExperiences__quote a {
			text-decoration: underline;
		}

		// handle atom classes
		.grid {
			display: -ms-grid;
		}
		.grid--cols-8 {
			-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		}
		.grid--cols-6 {
			-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		}
		.grid--cols-12 {
			-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		}

		// handle components
		.Icon--type-hamburger {
			span {
				margin-bottom: 4px;
			}
		}
		.DcHeader {
			//background-color: fade( red, 10 );
			//&__inner > * { outline: 1px solid red; }

			&__navCol {
				//-ms-grid-row: 1;
				-ms-grid-column: 1;
				-ms-grid-column-span: 3;
			}
			&__logoCol {
				//-ms-grid-row: 1;
				-ms-grid-column: 4;
				-ms-grid-column-span: 2;
			}

			@media @mediaQuery__md {
				&__navCol {
					-ms-grid-column-span: 1;
				}
				&__logoCol {
					-ms-grid-column: 2;
					-ms-grid-column-span: 6;
				}
			}
		}
		.DcFooter {
			//&__inner > * { outline: 1px solid red; }

			&__col--1 {
				//-ms-grid-row: 1;
				-ms-grid-column: 1;
				-ms-grid-column-span: 4;
			}
			&__col--2 {
				//-ms-grid-row: 1;
				-ms-grid-column: 5;
				-ms-grid-column-span: 2;
			}
			&__col--3 {
				//-ms-grid-row: 1;
				-ms-grid-column: 7;
				-ms-grid-columns-span: 2;
			}

			@media @mediaQuery__md {
				&__col--1 {
					-ms-grid-column-span: 3;
				}
				&__col--2 {
					-ms-grid-column: 4;
					-ms-grid-column-span: 2;
				}
				&__col--3 {
					-ms-grid-column: 6;
					-ms-grid-column-span: 3;
				}
			}
		}
		.DcText {
			//&__inner > * { outline: 1px solid red; }

			&__text {
				//-ms-grid-row: 1;
				-ms-grid-column: 2;
				-ms-grid-column-span: 6;
			}
		}
		.DcContactForm {
			//&__inner > * { outline: 1px solid red; }

			&__form {
				-ms-grid-column: 2;
				-ms-grid-column-span: 6;
			}
			&__input {
				-ms-grid-column-span: 3;
			}
			&__input:nth-child(1) {
				margin-bottom: @app[dtColGap];
				margin-right: @app[dtColGap]*0.5;
				-ms-grid-row: 1;
			}
			&__input:nth-child(2) {
				margin-bottom: @app[dtColGap];
				margin-left: @app[dtColGap]*0.5;
				-ms-grid-row: 1;
				-ms-grid-column: 4;
			}
			&__input:nth-child(3) {
				margin-bottom: @app[dtColGap];
				margin-right: @app[dtColGap]*0.5;
				-ms-grid-row: 2;
			}
			&__textarea {
				-ms-grid-row: 3;
				-ms-grid-column-span: 6;
			}

			&__successMessage {
				-ms-grid-column: 2;
				-ms-grid-column-span: 6;
			}

			@media @mediaQuery__dt {
				&__successMessage {
					.font--sizeBig;

					grid-column: span 6;
					grid-column-start: 2;
					grid-column-end: 8;
				}
			}
			@media @mediaQuery__md {
				&__successMessage {
					.font--sizeBig;

					grid-column: span 6;
					grid-column-start: 2;
					grid-column-end: 8;
				}
			}
			@media @mediaQuery__sm {
				&__form {
					grid-column: span 8;
				}
				&__input {
					grid-column: span 6;
				}
				&__textarea {
					grid-column: span 6;
				}

				&__input,
				&__textarea {
					height: 33px;
				}
				&__textarea {
					height: 140px;
					padding-top: 0.65em;
				}
				&__submitBtn {
					padding-left: 0.65em;
					padding-right: 0.65em;
				}

				&__successMessage {
					.font--sizeDefault;

					grid-column: span 8;
				}
			}
		}
		.DcImageText {
			//&__inner > * { outline: 1px solid red; }

			&__image {
				//grid-column: span 2;
				//-ms-grid-row: 1;
				//-ms-grid-column: 2;
				-ms-grid-column-span: 2;
			}
			&__text {
				position: relative; // ansonsten ist die box im ie nicht sichtbar
				//grid-column: span 3;
				-ms-grid-column: 5;
				-ms-grid-column-span: 3;
			}
			&__spacer {}

			@media @mediaQuery__dt {
				&__image {
					//grid-column: span 4;
					-ms-grid-column-span: 4;
					display: flex;
					align-items: center;
				}

				&__spacer--1 { display: none; }
				&__spacer--2 { display: none; }
			}
			@media @mediaQuery__md {
				&__image {
					grid-column: span 8;
					-ms-grid-column-span: 8;
				}
				&__text {
					-ms-grid-row: 2;
					-ms-grid-column-span: 6;
					-ms-grid-column: 2;
					//grid-column-start: 2;
					//grid-column-end: 8;
				}
				&__spacer--1 { display: none; }
				&__spacer--2 { display: none; }
				&__spacer--3 {
					-ms-grid-row: 2;
					-ms-grid-column-span: 1;
					//grid-column: span 1;
				}
			}
			@media @mediaQuery__sm {
				&__image {
					-ms-grid-column-span: 8;
				}
				&__text {
					-ms-grid-row: 2;
					-ms-grid-column-span: 8;
				}
				&__spacer {
					display: none;
				}
			}
		}
		.DcLocations {
			&__inner {
				display: flex;
				-ms-flex-flow: row wrap;
			}

			&__card {
				width: calc( 50% - @app[dtColGap]);
				margin-right: @app[dtColGap];
				margin-bottom: @app[dtColGap];
			}

			@media @mediaQuery__md {
				&__card {
					width: 100%;
					margin-right: 0;
				}
			}
		}
		.DcFeatures {
			// switch from grid to flex-box to
			// handle the layout rows
			&__inner {
				display: flex;
				-ms-flex-flow: row wrap;
			}
			&__feature {
				//outline: 1px solid red;
				//grid-column: span 2;
				//-ms-grid-column-span: 2;
				width: calc( 25% - @app[dtColGap]);
				margin-right: @app[dtColGap];
				margin-bottom: @app[dtColGap];
			}

			// da ie kein auto placement für
			// grid-cells kann werden die zeilen- und
			// spaltensprünge hier fest eingetragen.
			// ich gehe mal davon aus, dass ein 4x5 raster,
			// also maximal 20 angebote, ausreicht
			/*
			&__feature:nth-child(1),
			&__feature:nth-child(5),
			&__feature:nth-child(9),
			&__feature:nth-child(13),
			&__feature:nth-child(17) {
				-ms-grid-column: 1;
			}
			&__feature:nth-child(2),
			&__feature:nth-child(6),
			&__feature:nth-child(10),
			&__feature:nth-child(14),
			&__feature:nth-child(18) {
				-ms-grid-column: 3;
			}
			&__feature:nth-child(3),
			&__feature:nth-child(7),
			&__feature:nth-child(11),
			&__feature:nth-child(15),
			&__feature:nth-child(19) {
				-ms-grid-column: 5;
			}
			&__feature:nth-child(4),
			&__feature:nth-child(8),
			&__feature:nth-child(12),
			&__feature:nth-child(16),
			&__feature:nth-child(20) {
				-ms-grid-column: 7;
			}

			&__feature:nth-child(5),
			&__feature:nth-child(6),
			&__feature:nth-child(7),
			&__feature:nth-child(8) {
				-ms-grid-row: 2;
			}
			&__feature:nth-child(9),
			&__feature:nth-child(10),
			&__feature:nth-child(11),
			&__feature:nth-child(12) {
				-ms-grid-row: 3;
			}
			&__feature:nth-child(13),
			&__feature:nth-child(14),
			&__feature:nth-child(15),
			&__feature:nth-child(16) {
				-ms-grid-row: 3;
			}
			&__feature:nth-child(17),
			&__feature:nth-child(18),
			&__feature:nth-child(19),
			&__feature:nth-child(20) {
				-ms-grid-row: 3;
			}
			*/
		}
		.DcFeature {
			&__inner {
				-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
			}

			@media @mediaQuery__dt {
				&__header {
					-ms-grid-column: 1;
					-ms-grid-column-span: 2;
				}
				&__imageWrapper {
					-ms-grid-column: 1;
					-ms-grid-column-span: 2;
					-ms-grid-row: 2;
					margin-top: @app[dtColGap];
					margin-right: @app[dtColGap];
				}
				&__body {
					-ms-grid-column: 3;
					-ms-grid-column-span: 6;
					-ms-grid-row-span: 2;
				}
			}
			@media @mediaQuery__md {
				&__inner {}

				&__header {
					-ms-grid-column: 1;
					-ms-grid-column-span: 2;
				}
				&__imageWrapper {
					-ms-grid-column: 1;
					-ms-grid-column-span: 4;
					-ms-grid-row: 2;
					margin-top: @app[dtColGap];
				}
				&__body {
					-ms-grid-column: 3;
					-ms-grid-column-span: 6;
				}
			}
		}
		.DcOffers {
			&__inner {}
			&__header {}

			// switch from grid to flex-box to
			// handle the layout rows
			&__body {
				display: flex;
				-ms-flex-flow: row wrap;
			}
			&__card {
				width: calc( 25% - @app[dtColGap]);
				margin-right: @app[dtColGap];
				margin-bottom: @app[dtColGap];
			}

			@media @mediaQuery__md {
				&__card {
					//grid-column: span 4;
					width: calc( 50% - @app[dtColGap]);
				}
			}
		}
		.DcOffer {
			&__inner {
				-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
			}

			@media @mediaQuery__dt {
				&__header {
					-ms-grid-column: 1;
					-ms-grid-column-span: 2;
				}
				&__imageWrapper {
					-ms-grid-column: 1;
					-ms-grid-column-span: 2;
					-ms-grid-row: 2;
					margin-top: @app[dtColGap];
					margin-right: @app[dtColGap];
				}
				&__body {
					-ms-grid-column: 3;
					-ms-grid-column-span: 6;
					-ms-grid-row-span: 2;
				}
			}
			@media @mediaQuery__md {
				&__header {
					-ms-grid-column: 1;
					-ms-grid-column-span: 8;
				}
				&__imageWrapper {
					-ms-grid-row: 2;
					-ms-grid-column: 1;
					-ms-grid-column-span: 2;
					margin-top: @app[dtColGap];
				}
				&__body {
					-ms-grid-row: 2;
					-ms-grid-column: 3;
					-ms-grid-column-span: 6;
				}
			}
		}
		.DcExperiences {
			&__inner {}
			&__slideContent	{}

			&__imageWrapper {}
			&__image {}
			&__quote {}
			&__quoteText {}
			&__quoteAuthor {}

			@media @mediaQuery__dt {
				&__inner {}

				&__imageCell {
					-ms-grid-column: 1;
					-ms-grid-column-span: 4;
					margin-right: @app[dtColGap]*0.5;
				}
				&__textCell {
					-ms-grid-column: 5;
					-ms-grid-column-span: 4;
					margin-left: @app[dtColGap]*0.5;
				}
			}
			@media @mediaQuery__md {
				&__inner {}

				&__imageCell {
					-ms-grid-column: 1;
					-ms-grid-column-span: 5;
					margin-right: @app[dtColGap]*0.5;
				}
				&__textCell {
					-ms-grid-column: 6;
					-ms-grid-column-span: 3;
					margin-left: @app[dtColGap]*0.5;
				}
			}
		}
		.DcHome {
			@media @mediaQuery__dt {
				&__inner {
					padding-bottom: 0;
				}
				&__grid {
					position: relative;
					height: 50vw;
				}
				&__slider {
					height: inherit;
					-ms-grid-row: 1;
					-ms-grid-column: 1;
					-ms-grid-column-span: 5;
					margin-right: @app[dtColGap]*0.5;
				}
				&__claim {
					-ms-grid-row: 1;
					-ms-grid-column: 6;
					-ms-grid-column-span: 3;
					margin-left: @app[dtColGap]*0.5;
				}
			}
			@media @mediaQuery__md {
				&__slider {
					height: 50vw;
					//grid-column: span 8;
					-ms-grid-row: 1;
					-ms-grid-column: 1;
					-ms-grid-column-span: 8;
					margin-bottom: @app[dtColGap];
				}
				&__navItems {
					-ms-grid-row: 2;
					-ms-grid-column: 1;
					-ms-grid-column-span: 2;
					//margin-right: 0;
				}
				&__claim {
					-ms-grid-row: 2;
					-ms-grid-column: 3;
					-ms-grid-column-span: 6;
					height: 70vw; // padding-bottom doesnt work in ie
				}
			}
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
