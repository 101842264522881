import Vue from 'vue'
import Router from 'vue-router'
import HomeView from '@/views/HomeView.vue'

Vue.use(Router)

// Handle scrollRestoration manual, stops browser from doing it
if ('scrollRestoration' in window.history) {
	window.history.scrollRestoration = 'manual';
}

export default new Router({
	base: '/',
	mode: 'history',
	linkActiveClass: 'routerLink--active',
	linkExactActiveClass: 'routerLink--exactActive',
	routes: [
		{
			path: '/',
			name: 'HomeView',
			component: HomeView,
			//pathToRegexpOptions: { strict: true },
		},
		//{
		//	path: '/erfahrungen/',
		//	name: 'ExperiencesView',
		//	component: () =>
		//		import( /* webpackChunkName: "ExperiencesView" */ '@/views/ExperiencesView.vue')
		//},
		{
			path: '/camp/:slug/',
			name: 'FeatureView',
			pathToRegexpOptions: {strict: true},
			component: () =>
				import( /* webpackChunkName: "FeatureView" */ '@/views/FeatureView.vue')
		},
		{
			path: '/angebote/:slug/',
			name: 'OfferView',
			pathToRegexpOptions: {strict: true},
			component: () =>
				import( /* webpackChunkName: "OfferView" */ '@/views/OfferView.vue')
		},
		{
			path: '/:slug',
			name: 'PageView',
			component: () =>
				import( /* webpackChunkName: "PageView" */ '@/views/PageView.vue')
		},
		{
			path: "*",
			name: 'NotFoundView',
			component: () =>
				import( /* webpackChunkName: "NotFoundView" */ '@/views/NotFoundView.vue')
		},
	]
})
