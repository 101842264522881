/**
 * In dieser Datei werden die verschiedenen Environments für die App angelegt.
 * Die Environments werden anhand des jeweiligen hostname unterschieden.
 * Es kann beliebig viele Pairs hinzugefügt werden, Bedingung ist lediglich
 * der hostname.
 *
 * Das ist z.B. sinnvoll, wenn die locale Dev-Umgebung
 * eine andere Rest-API benötigt als die Live-Umgebung.
 */

export default [
	{
		name: 'local',
		hostname: 'localhost',
	},
	{
		name: 'local-2',
		hostname: 'digicamp.local',
	},
	{
		name: 'stage',
		hostname: 'dev.digi.camp',
	},
	{
		name: 'live',
		hostname: 'digi.camp',
	},
]
