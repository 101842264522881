<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcHome contentModule" :class="elmClasses">
		<div class="DcHome__inner relative" ref="innerElm">
			<!-- dt and above -->
			<template v-if="!$root.isMqBelowDt">
				<div class="DcHome__grid grid grid--cols-8 vSpace hSpace hSpace--colGap">
					<div class="DcHome__slider" ref="slider">
						<div class="DcHome__sliderInner">
							<div class="DcHome__sliderTrack" ref="sliderTrack" :style="sliderTrackStyles">
								<router-link
									class="DcHome__slide"
									:to="'/erfahrungen/?slide=' + (index+1)"
									v-for="(post, index) in posts" :key="index">
									<MhImage class="DcHome__slideImg"
										:mode="'cover'"
										:imageObject="post.acf.image"
										:title="_.get( post, 'acf.image.title' )"
										:alt="_.get( post, 'acf.image.alt' )"
										:useRelativeUrl="true"
										:useBackgroundImage="$root.isIE"
									></MhImage>
								</router-link>
							</div>
						</div>
					</div>
					<div class="DcHome__claim">
						<div class="DcHome__claimInner background background--magenta">
							<h2 class="DcHome__claimText">Jeder kann Zukunft</h2>
							<div class="DcHome__claimImage"></div>
						</div>
					</div>
				</div>
			</template>

			<!-- md and below -->
			<template v-else>
				<div class="DcHome__grid grid grid--cols-8 hSpace hSpace--colGap">
					<div class="DcHome__slider" ref="slider">
						<div class="DcHome__sliderInner">
							<div class="DcHome__sliderTrack" ref="sliderTrack" :style="sliderTrackStyles">
								<router-link
									class="DcHome__slide"
									:to="'/erfahrungen/?slide=' + (index+1)"
									v-for="(post, index) in posts" :key="index">
									<MhImage class="DcHome__slideImg"
										:mode="'cover'"
										:imageObject="post.acf.image"
										:title="_.get( post, 'acf.image.title' )"
										:alt="_.get( post, 'acf.image.alt' )"
										:useRelativeUrl="true"
										:useBackgroundImage="$root.isIE"
									></MhImage>
								</router-link>
							</div>
						</div>
					</div>
					<nav class="DcHome__navItems">
						<router-link
							class="DcHome__navItem block color color--inherit"
							:to="'/' + navItem.link.post_name + '/'"
							v-for="(navItem, index) in navItems" :key="index"
						>{{navItem.title}}</router-link>
					</nav>
					<div class="DcHome__claim">
						<div class="DcHome__claimInner background background--magenta">
							<h2 class="DcHome__claimText">Jeder kann Zukunft</h2>
							<div class="DcHome__claimImage"></div>
						</div>
					</div>
				</div>
			</template>
		</div>
		<!--
		<a @click="doSlide()">doSlide()</a>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/RestHandler/RestHandler.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'DcHome',
		components: {
			MhImage,
		},
		mixins: [
			RestHandler,
		],
		props: {},
		data() {
			return {
				posts  : null,
				slides : {
					translateX   : 0,
					countSlides  : null,
					currentSlide : 1,
					tick         : null,
					interval     : 4000,
				}
			}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
			navItems(){
				return this.app.acfOptions ? this.app.acfOptions.mainNavItems : []
			},
			sliderTrackStyles(){
				const translateX = this.slides.translateX

				return {
					transform : 'translateX(-'+translateX+'px)',
				}
			},
		},
		methods: {
			fetchExperiences( callback = ()=>{} ){
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts',
					params : {
						postType : 'experience_post',
						perPage : 99,
					},
					callback : (response) => {
						const doLog  = false
						const result = response.data.result

						if( doLog ){
							console.groupCollapsed('%cthis.fetchExperiences() callback', 'background-color : lightgrey', result.length)
							console.log(' response:', response)
							console.log('   result:', result)
							console.groupEnd()
						}

						callback( response )
					},
				})
			},
			resetSlides(){ // reset slider on resize
				this.slides.translateX   = 0
				this.slides.currentSlide = 1
			},
			XXXsetHeight( doLog = true ){
				setTimeout(()=>{
					const isMqBelowDt     = this.$root.isMqBelowDt
					const innerElm        = this.$refs.innerElm
					const gridElm         = document.querySelector('.DcHome__grid')
					const innerElmHeight  = innerElm ? innerElm.clientHeight : null

					const sliderElm       = document.querySelector('.DcHome__slider')
					const sliderElmWidth  = sliderElm ? sliderElm.clientWidth : null
					const sliderElmHeight = sliderElmWidth * 0.55

					if( doLog ){
						console.group('setHeight()')
						console.log('    isMqBelowDt:', isMqBelowDt)
						console.log(' --------------')
						console.log('       innerElm:', innerElm)
						console.log(' innerElmHeight:', innerElmHeight)
						console.log('        gridElm:', gridElm)
						console.log(' --------------')
						console.log('      sliderElm:', sliderElm)
						console.log(' sliderElmWidth:', sliderElmWidth)
						console.log('sliderElmHeight:', sliderElmHeight)
						console.groupEnd()
					}

					// set height for dt and above
					if( gridElm && innerElmHeight && !this.$root.isMqBelowDt ){
						//gridElm.style.height = innerElmHeight + 'px'
					}

					if( this.$root.isMqBelowDt && sliderElm ){
						//sliderElm.style.height = sliderElmHeight + 'px'
					}
				}, 50)
			},
			doSlide( doLog = false ){
				const slider       = this.$refs.slider
				const sliderWidth  = slider.clientWidth

				const sliderTrack  = this.$refs.sliderTrack
				const trackWidth   = sliderTrack ? sliderTrack.clientWidth : 0

				const colGap       = this.$root.isMqBelowMd ? 11 : 15
				const firstSlide   = this.$refs.sliderTrack.children[0]
				const slideWidth   = firstSlide ? firstSlide.clientWidth : 0

				const currentSlide = this.slides.currentSlide
				const countSlides  = this.slides.countSlides
				const colsPerSlide = this.$root.isMqBelowMd ? 4 : 3
				let   translateX
				let   styles

				// damit am ende des slide-tracks keine weißen lücken entstehen
				// wird, je nach breakpoint, nur eine bestimmte anzahl an slides
				// durchgeführt bevor wieder zum anfang gesprungen wird
				let limiter = 1
				if( this.$root.isMqBelowDt ) limiter = 2
				if( this.$root.isMqBelowMd ) limiter = 1

				if( currentSlide < countSlides - limiter ){
					translateX = this.slides.translateX + slideWidth + colGap
					//translateX = translateX + 1 // hackfix für rundungsfehler
					this.slides.currentSlide++
				}
				else{
					translateX = 0
					this.slides.currentSlide = 1
				}

				this.slides.translateX = translateX

				if( doLog ){
					console.groupCollapsed('doSlide()')
					console.log('      slider:', slider)
					console.log(' sliderWidth:', sliderWidth)
					console.log('  firstSlide:', firstSlide)
					console.log('  slideWidth:', slideWidth)
					console.log('currentSlide:', currentSlide)
					console.log(' countSlides:', countSlides)
					console.log('  translateX:', translateX)
					console.groupEnd()
				}
			},
		},
		created() {
			window.addEventListener('resize', this.resetSlides )
			//window.addEventListener('resize', this.setHeight )
		},
		mounted() {
			this.fetchExperiences( (response)=>{
				const result = response.data.result
				const repeat = 1
				const multiPosts = this._.flatten( Array(repeat).fill( result ) )

				this.posts              = multiPosts
				this.slides.countSlides = this.posts.length
			})

			// doSlide needs some delay
			setTimeout(()=>{
				this.slides.tick = setInterval(()=>{
					this.doSlide()
				}, this.slides.interval)
			}, 50)

			// layout on load needs some delay
			/*
			setTimeout(()=>{
				this.setHeight()
			}, 50)
			*/
		},
		beforeDestroy(){
			window.removeEventListener('resize', this.resetSlides )
			//window.removeEventListener('resize', this.setHeight )

			clearInterval( this.slides.tick )
		},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcHome {
		//&__inner { background-color: fade( yellow, 40 ); }
		//&__slider { background-color: fade( blue, 40 ); & > * { opacity: 0; } }
		//&__claim { background-color: fade( green, 80 ); & > * { opacity: 0; } }

		&__slider,
		&__navItems,
		&__claim {
			[showBorders4] & { .outline( green ); }
		}

		&__inner {
			width: 100%;
		}
		&__grid {
			[showBorders4] & { background-color: fade(red, 20); }
		}

		&__slider {
			[showBorders4] & { background-color: fade(yellow, 70); }

			position: relative;
		}
		&__sliderInner {
			[showBorders4] & { background-color: fade(red, 20); }

			position: absolute;
			top: 0; left: 0em; right: 0; bottom: 0;
			overflow: hidden;
		}
		&__sliderTrack {
			transition : all 0.5s ease;

			position: absolute;
			top: 0; left: 0; bottom: 0; right: 0;
			display: flex;
		}
		&__slide {
			[showBorders4] & { .outline( black ); }

			position: relative;
			flex-grow: 0;
			flex-shrink: 0;
			border-radius: 7px;
			overflow: hidden;
		}
		&__slideLink {
			position: relative;
			display: block;
		}
		&__slideImg {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}

		&__navItem { // vertical offset fix
			&:first-child { margin-top: -6px; }
		}

		&__claim {
			position: relative;
		}
		&__claimInner {
			position: absolute;
			top: 0; left: 0;
			width: 100%; height: 100%;
			border-radius: 7px;
		}
		&__claimText {  // hidden text
			position: absolute;
			top: 0; left: 0;
			opacity: 0;
			pointer-events: none;
		}
		&__claimImage { // claim svg
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			border: 0px solid transparent;

			background-image: url('../assets/imgs/img--claim.svg');
			background-position: top left;
			background-repeat: no-repeat;
			background-size: contain;
		}

		@media @mediaQuery__dt {
			@ratioPadding : 51%; // achtung, es gibt noch ein slider-padding!
			@colGap       : @app[dtColGap];

			padding-bottom: 60px;

			&__inner { padding-bottom: @ratioPadding; }
			&__grid { position: absolute; top: 0; left: 0; width: 100%; }

			// safari fix, he doesnt span the childs with height:100%
			&__slider { padding-bottom: 83.75%; }
			//&__slider { height: inherit !important; }

			&__claim { grid-column: span 3; }
			&__claimInner { transform: translateY(-70px); height: calc( 100% + 70px ); } // layout raffinesse: claim ist nach oben verschoben
			&__claimImage { border-width: 15px; }

			&__slider { grid-column: span 5; }
			&__sliderInner { left: -@colGap; border-top-right-radius: 7px; border-bottom-right-radius: 7px; }
			&__slide { width: calc( 60% - @colGap*1 ); }
			&__slide { margin-left: @colGap; }
		}
		@media @mediaQuery__md {
			@ratioPadding : 55%;
			@colGap       : @app[mdColGap];

			padding-bottom: 45px;

			&__inner {}

			&__grid { height: auto !important; }

			&__slider { padding-bottom: @ratioPadding; grid-column: span 8; }
			//&__slider { grid-column: span 8; }
			&__sliderInner { left: -@colGap; right: -@colGap; }
			&__slide { width: calc( (100%/8)*3 - @colGap*1.33 ); }
			&__slide { margin-left: @colGap; }

			&__navItems { grid-column: span 2; }

			&__claim { grid-column: span 6; }
			&__claim { padding-bottom: 77%; }
			&__claimImage { border-width: 15px; }

			/*
			// ratio + padding
			&__inner { padding-bottom: 45px; }
			&__slider { padding-bottom: 58%; }

			// slider
			&__slide { width: calc( ( ( ( 100vw - @app[mdColGap]*2 ) / 8 ) * 3 ) - @app[mdColGap] ); }
			&__slide { margin-left: @app[mdColGap]; }

			// grid
			&__slider { grid-column: span 8; }
			&__navItems { grid-column: span 2; }
			&__claim { grid-column: span 6; }
			*/
		}
		@media @mediaQuery__sm {
			@ratioPadding : 72%;
			@colGap       : @app[smColGap];

			padding-bottom: 25px;

			&__inner {}

			&__navItems { display: none; }
			&__claim { grid-column: span 8; }

			&__slider { height: auto !important; }
			&__slider { padding-bottom: @ratioPadding; grid-column: span 8; }
			&__sliderInner { left: -@colGap; right: -@colGap; }
			&__slide { width: calc( 50% - @colGap*1.5 ); }
			&__slide { margin-left: @colGap; }


			/*
			// ratio + padding
			&__inner { padding-bottom: 33px; }
			&__claim { padding-bottom: 77%; }
			&__claimImage { border-width: 11px; }
			&__slider { padding-bottom: 76%; }

			// slider
			&__slide { width: calc( ( ( ( 100vw - @app[smColGap]*2 ) / 8 ) * 4 ) - @app[smColGap] ); }
			&__slide { margin-left: @app[smColGap]; }

			// grid
			&__slider { grid-column: span 8; }
			&__navItems { display: none; }
			&__claim { grid-column: span 8; }
			*/
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
