<!--

	Mit dieser Componente kann die Tag-Struktur
	(Überschriften, Absätze, Listen ... )
	bestimmter Selectoren sichtbar gemacht werden.
	Hilfreich für SEO.

	Beispiel Code:
		<MhTagsOutlining
			:doLog="true"
			:color="'rgba( 255, 0, 175, 0.5 )'"
			:selectors="[
				'[showborders3] .view'
			]"
		></MhTagsOutlining>

	2021-12-13	init

-->

<template>
	<div class="MhTagsOutlining"></div>
</template>

<script>
	// import { isEqual, isArray, isString, includes } from 'lodash'

	/**
	 * @param {String} className representing a class name
	 * @param {DomeNode} queryNode representing the root node to query in
	**/
	function removeElementsByClass( className, queryNode, doLog = false ){
		if( doLog ){
			console.groupCollapsed( 'removeElementsByClass( className, queryNode )')
			console.log('className', className)
			console.log('queryNode', queryNode)
			console.groupEnd()
		}

		const elements = queryNode ? queryNode.getElementsByClassName( className ) : []

		while( elements.length > 0 ){
			elements[0].parentNode.removeChild( elements[0] )
		}
	}

	export default {
		name: 'MhTagsOutlining',
		components: {},
		mixins: [],
		props: {
			doLog: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			color: {
				type     : [String],
				default  : 'rgba( 255, 0, 175, 0.5 )',
				required : false,
			},
			selectors: {
				type     : [Array],
				default  : ()=>{ return [] },
				required : false,
			},
		},
		data(){
			return {
				outlineBlockElms : [
					'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
					'ol', 'ul',
					'pre',
					'p',
				]
			}
		},
		watch: {},
		computed: {},
		methods: {
			setCss( doLog = true ){
				const head = document.head
				const color = this.color
				let elmCss = `
					position: relative;
					outline: 1px solid ${color};
					outline-offset: -1.5px;
				`
				let elmLabelCss = `
					position: absolute;
					top: 0; right: 0;
					padding: 0.2em 0.3em;
					background-color: ${color};
					color: #fff;
					font-size: 11px;
					font-family: arial, sans-serif;
					line-height: 1em;
					text-transform: uppercase;
				`
				let cssStr = '\n'

				elmCss = elmCss.replace(/\t+/g, '  ');
				elmLabelCss = elmLabelCss.replace(/\t+/g, '  ');

				// build css string
				this.selectors.forEach((k1, v1)=>{
					this.outlineBlockElms.forEach((k2, v2)=>{
						cssStr += k1 + ' ' + k2 + ' {' + elmCss + '}' + "\n"
						cssStr += k1 + ' ' + k2 + ':after {' + elmLabelCss + 'content: "' + k2 + '";\n}' + "\n"
					})

					cssStr += "\n"
				})

				// remove eventually earlier added style-element
				removeElementsByClass( 'MhTagsOutlining__styleElm', head )
				// insert new style-element
				head.insertAdjacentHTML(
					"beforeend",
					'<style type="text/css" class="MhTagsOutlining__styleElm">'+
						cssStr+
					'</style>'
				)

				// groupCollapsed group
				if( doLog || this.doLog ){
					console.groupCollapsed( this.$options.name, '• setCss()' )
					console.log( 'selectors:', this.selectors )
					console.log( 'elmCss:', elmCss )
					console.log( 'elmLabelCss:', elmLabelCss )
					console.log( 'cssStr:', cssStr )
					console.groupEnd()
				}
			},
		},
		created(){},
		mounted(){
			this.setCss()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	.MhTagsOutlining {}
</style>
