<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Dummy></Dummy>

	2019-06-08	init

-->

<template>
	<div class="DcFooter contentModule background" :class="elmClasses" v-if="app.acfOptions">
		<div class="DcFooter__inner grid grid--cols-8 hSpace hSpace--colGap vSpace vSpace--rowGap">
			<div class="DcFooter__col DcFooter__col--1" v-html="app.acfOptions.footerFirstCol"></div>
			<div class="DcFooter__col DcFooter__col--2" v-html="app.acfOptions.footerSecondCol"></div>
			<div class="DcFooter__col DcFooter__col--3" v-html="app.acfOptions.footerThirdCol"></div>
		</div>
		<!--
		<pre name="bgColor">{{bgColor}}</pre>
		<pre>{{app.acfOptions}}</pre>
		-->
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'DcFooter',
		components: {},
		mixins: [],
		props: {},
		data() {
			return {}
		},
		watch: {},
		computed: {
			app() {
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.app.footerColor ) classes.push('background--' + this.app.footerColor)

				return classes
			},
		},
		methods: {},
		created() {},
		mounted() {},
		beforeDestroy(){},
	}
</script>

<style lang="less">
	@import "../less/vars.less";
	@import "../less/mixins.less";
	@import (reference) "../less/atoms.less";

	.DcFooter {
		//background-color: @swatches[magenta];

		&__col {}
		&__col--1 { grid-column: span 4; }
		&__col--2 { grid-column: span 2; }
		&__col--3 { grid-column: span 2; }

		@media @mediaQuery__dt {
			padding-top: 40px;
		}
		@media @mediaQuery__md {
			padding-top: 25px;

			&__col--1 { grid-column: span 3; }
			&__col--3 { grid-column: span 3; }
		}
		@media @mediaQuery__sm {
			padding-top: 20px;

			.font--sizeSmall;

			&__col--1 { grid-column: span 4; }
			&__col--2 { grid-column: span 4; }
			&__col--3 { grid-column-start: 5; grid-column-end: 9; }
		}
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__dt {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
