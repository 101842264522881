<template>
	<div class="HomeView view">

		<DcHome></DcHome>

		<div class="view__spacer"></div>

		<DcFooter></DcFooter>

	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/RestHandler/RestHandler.js'
	import ViewMixin from './View.mixin.js'

	import DcHome from '@/components/DcHome.vue'
	import DcFooter from '@/components/DcFooter.vue'

	export default {
		name: 'HomeView',
		components: {
			DcHome,
			DcFooter,
		},
		mixins: [
			RestHandler,
			ViewMixin,
		],
		directives: {},
		props: {},
		data() {
			return {
				posts: [],
			}
		},
		computed: {
			app() {
				return this.$root.$children[0]
			},
		},
		methods: {},
		mounted() {
			const fakeHomePost = {
				acf : {
					color : {
						label : 'magenta'
					}
				}
			}
			EventBus.$emit('pageLoaded', fakeHomePost)

			this.viewIsReady = true
		},
	}
</script>

<style lang="less">
	@import "../less/includes/grid.include.less";

	.HomeView {
		//padding: 1em;
	}

	@media @mediaQuery__xs {}
	@media @mediaQuery__sm {}
	@media @mediaQuery__md {}
	@media @mediaQuery__lg {}
	@media @mediaQuery__xl {}
</style>
