var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app relative handleModuleBgOverflow handleVSpacing"},[_c('DcHeader'),_c('DcMainNav'),_c('main',{staticClass:"app__body",class:_vm.bodyClasses},[_c('transition',{key:_vm.$route.path,attrs:{"name":"fade","mode":"out-in","duration":{ leave: 200, enter: 300  },"appear":""}},[_c('router-view',{key:_vm.$route.path,staticClass:"app__view"})],1)],1),_c('DcBookEvent'),_c('DcCookieNotice'),_c('MhSpinner',{staticClass:"app__loadingSpinner",class:_vm.spinnerClasses}),_vm._m(0),_c('IEHacks'),_c('MhTagsOutlining',{attrs:{"doLog":true,"color":'rgba( 255, 0, 175, 0.5 )',"selectors":[
			'[showborders3] .DcText',
			'[showborders3] .DcImageText',
			'[showborders3] .DcOffer',
			'[showborders3] .DcTeam',
			'[showborders3] .DcLocations',
			'[showborders3] .DcOffers' ]}}),_c('MhHeadInfos',{attrs:{"doLog":_vm.$root.currentEnv.name === 'local',"showDebugPanel":_vm.$root.currentEnv.name === 'xxxlocal',"useCaching":true}}),_c('MhDelegateLinks'),_c('MhDevInfos',{attrs:{"doLog":false,"showOnHosts":[
			'localhost',
			'digicamp.local',
			'dev.digi.camp' ],"show":_vm._.get( _vm.currentUser, 'display_name' ) ? true : undefined}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gridDebug grid grid--cols-8 hSpace hSpace--colGap"},[_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div')])}]

export { render, staticRenderFns }